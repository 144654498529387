import db from "./components/tiresDB";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import TiresList from "./components/tiresList/TiresList.js";
import Filter from "./components/filter/Filter";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";

function App() {

  const themeFilter = createTheme({
    typography: {
      fontFamily: [
        'DM Mono',
        'monospace',
      ].join(','),
    },
  });
//Azeret Mono
  const themeContent = createTheme({
    typography: {
      fontFamily: [
        // 'Cutive Mono',
        'monospace'
      ].join(','),
    },
  });

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#FAFAFA", //white
        light: "#171717", //lightBlack
        dark: "#111111" //darkBlack
      },
      secondary: {
        main:  "#B8B8B8", //lightGray
        light: "#81B225", //green
        dark: "#FF4E00", //orange
      },
      typography: {
        fontFamily: [
          'DM Mono',
          'monospace',
        ].join(','),
      },
    }
  })

  //colors: green(#81B225), orange(#FF4E00), white(#FAFAFA), lightGray(#B8B8B8), darkBlack(#111111), lightBlack(#222222)


   //@TODO: remove variables from App.js and use another method
  const[selectedSize, setSelectedSize] = useState("");
  const[selectedSeason, setSelectedSeason] = useState("");


  return (
    <>
    <ThemeProvider theme={customTheme}>
    <Header />
    <ThemeProvider theme={themeFilter}>
      <Filter tires={db.tiresDB} setSelectedSize={setSelectedSize} setSelectedSeason={setSelectedSeason}/>
    </ThemeProvider>
    <ThemeProvider theme={themeContent}>
      <TiresList tires={db.tiresDB} selectedSize={selectedSize} selectedSeason={selectedSeason}/>
    </ThemeProvider>
    {/* <Footer /> */}
    </ThemeProvider>
    </>
  );
}

export default App;
