import { Card, Typography, Box, CardMedia } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import logo from "../imagesComponent/logo.kumi360.png";


const Info = () => {

  const theme = useTheme();
    const [data, setData]= useState(null);

    useEffect (() => {
      fetch ("http://localhost:3001/api")
        .then((res) => res.json())
        .then((data) => setData(data.message))
        .catch(err => console.error(err));
      }, []); 
    


    return (
      <Card variant="elevation" elevation={12}
        sx={{
          textAlign: "center",
          minHeight: 100,
          position: "relative",
          weight: "80%",
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.main,
          padding: "1px",
          fontSize: "1.5rem",
          margin: "1rem"
        }}>
        
        <CardMedia
          component="img"
          alt="logo"
          height="auto"
          sx={{
            maxWidth: "300px",
            width: "100%",
            margin: "0 auto",
            marginTop: "15px",
            display: "block",
            backgroundColor: "black",
            filter: "brightness(120%)"
          }}
          image={logo}
          loading="lazy"
        />
          
        <Typography variant="h5" lineHeight={"1"} color={"#FF4E00"} style={{marginBottom: "1rem"}}>
          <strong>
            KÄYTETYT RENKAAT ERINOMAISESSA KUNNOSSA
          </strong>
        </Typography>

        <Box textAlign="center">
          <Box display="flex" justifyContent="center" gap={2}
            sx={{ flexDirection: { xs: "column", sm: "row"}}}>
            <Typography variant="h6" color="#81B225" fontFamily="monospace">WhatsApp ja tekstiviesti:</Typography>
            <Typography variant="h6" color="#FF4E00" fontFamily="monospace">+358 41 728 4897</Typography>
          </Box>

          <Box display="flex" justifyContent="center" gap={2}
            sx={{ flexDirection: { xs: "column", sm: "row"}}}>
            <Typography variant="h6" color="#81B225" fontFamily="monospace">@:</Typography>
            <Typography variant="h6" color="#FF4E00" fontFamily="monospace">tilaus@kumi360.fi</Typography>
          </Box>

          <Box display="flex" justifyContent="center" gap={2}
            sx={{ flexDirection: { xs: "column", sm: "row"}}}>
            <Typography variant="h6" color="#81B225" fontFamily="monospace">Tukkukauppa:</Typography>
            <Typography variant="h6" color="#FF4E00" fontFamily="monospace">+358 40 688 6665 Enri</Typography>
          </Box>
        </Box>


        <p/>
          {!data ? <p style={{ color: "red" }}> 
          {/* Loading...  */}
        </p> : data } 
    
      </Card>
    );
};

export default Info;