import { TextField, MenuItem, Grid }from "@mui/material";
import "./Filter.css";
import { red } from "@mui/material/colors";


const TextFieldMui = ({ tires, name, value, setValue }) => {



    return (
      
      <Grid item xs={6} sm={4} md={3} lg={2} 
      sx={{
      textAlign: "center",
      paddingTop: "4px",
      paddingBottom: "4px",
    }}>

        <TextField
        value={value || ""}
        defaultValue= ""
        onChange={(e) => setValue(e.target.value)}
        select
        name="placeList"
        label={name}
        SelectProps={{
          displayEmpty: true,
          MenuProps: { 
            disableAutoFocusItem: true,
            PaperProps: {
              sx: {
                "& .MuiList-root": {
                  padding: "0",
                  margin: "0"
                }
              }
            }
          }
        }}
        InputLabelProps={{
          style: { color: "#81B225", fontSize: "1rem" }
        }}
        sx={{
          width: "90%",
          padding: "0",
          margin: "0",
          "& fieldset": {
            borderRadius: "3px",
            borderColor: "#4B4C4F",
            padding: "0",
            margin: "0"
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#81B225"
          },
          "& .MuiInputBase-root": {
            color: "#81B225",
            textTransform: "uppercase"
          }
        }}
      >


        {Array.from(tires).map(item =>
          <MenuItem 
          className="menuItem"
          key={item}
          value={item}
          sx={{
            padding: "0",
            margin: "0",
            height: "30px",
            textTransform: "uppercase",
            justifyContent: "center",
            color: "#81B225",
            backgroundColor: "#111111",
            opacity: "0.9",
            "&.Mui-selected" : {
              backgroundColor: "#FF4E00",
              color: "black"
            },
            "&.Mui-selected.Mui-focusVisible": {
              backgroundColor: "#FF4E00"
            },
            "&:hover" : {
              backgroundColor: "#de4300"
            },
            "&.Mui-selected:hover" : {
              backgroundColor: "#FF4E00"
            },
          }}
          >
            {item}
          </MenuItem>
        )}


      </TextField>
      </Grid>

    );
};

export default TextFieldMui;