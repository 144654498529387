import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from '@emotion/react';
import OneTire from "./OneTire";
import CustomPagination from "../customPagination/CustomPagination";
import { useEffect, useState, useCallback } from "react";
import "./TiresList.css";

const RenkaatList = ({ tires, selectedModel, selectedSize, selectedSeason }) => {

  const[filteredList, setFilteredList] = useState(tires);

  //=>
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); 
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const itemsPerPage = isSmallScreen ? 6 : isMediumScreen ? 8 : 12;

  const [currentItems, setCurrentItems] = useState([]);
  //<=

  // Filter function
  const filterTires = useCallback(() => {
    return tires.filter((tire) => {
      const matchesModel = selectedModel ? tire.model.split(" ").includes(selectedModel) : true;
      const matchesSize = selectedSize ? tire.size === selectedSize : true;
      const matchesSeason = selectedSeason ? tire.season === selectedSeason : true;

      return matchesModel && matchesSize && matchesSeason;
    });
  }, [tires, selectedModel, selectedSize, selectedSeason]);

  // Call filter function if some change
  useEffect(() => {
    setFilteredList(filterTires());
  }, [filterTires]);



  return (
    <>
      <Box sx={{ marginTop: "1rem", marginBottom: "4rem" }}>

        {filteredList.length === 0 ? (
          <Typography variant="h5" align="center" mt={4} color="red">
          Ei tuloksia
          </Typography>
        ) : (

        <Grid 
        container 
        direction="row"
        spacing={2} mt={2}
        paddingBottom={4}
        >
          {currentItems && currentItems.map(tire => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={tire.id} 
              sx={{
              textAlign: "center"
              }}>
                <OneTire key={tire.id} tire={tire}/>
            </Grid>
          ))}
        </Grid>
         )}

        <CustomPagination
          filteredList={filteredList} 
          itemsPerPage={itemsPerPage} 
          onPageItemsChange={setCurrentItems} />
      
      </Box>
    </>
    );
};

export default RenkaatList;